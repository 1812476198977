import React, { FC } from 'react'

import { Layout, Seo } from 'src/components'
import { Terms } from 'src/components/Terms'

const TermsPage: FC = () => (
  <Layout>
    <Seo title="Terms of Use" />

    <Terms />
  </Layout>
)

export default TermsPage
