import React, { FC } from 'react'
import { Link } from 'gatsby'

import { Box, Flex, Text } from 'src/components'

const Terms: FC = () => (
  <Box
    width="100%"
    maxWidth="848px"
    border="solid"
    borderRadius="l"
    mt={['30px', '60px']}
    height="fit-content"
    mx="16px"
    mb="61px"
    py={['32px', '64px']}
    px={['40px', '80px']}
    boxShadow="l"
    bg="background"
  >
    <Flex flexDirection="column">
      <Box mb="24px">
        <Text fontSize={['xlMob', 'xl']} fontWeight="bold">
          Terms of Use
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        The following Terms of Use apply to all users of www.hitchhero.com (the
        “Website”) and other services that may be offered by HitchHero (or
        insert proper LLC name) (“HitchHero,” “us”, “we” and/or “our”) from time
        to time (collectively, the “Services”). BY USING THE SERVICES, YOU AGREE
        TO THESE TERMS OF USE; IF YOU DO NOT AGREE TO THESE TERMS OF USE, PLEASE
        DO NOT USE THIS WEBSITE OR THE SERVICES. HitchHero reserves the right to
        change these Terms of Use from time to time at its sole discretion
        without notice to you. You agree to the changes to these Terms of Use by
        your continued use of Services after we post notice of such changes. If
        you do not agree to any changes we make to the Terms of Use, you must
        cease use of the Services. These Terms of Use were last modified as of
        October 30, 2017.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Who Can Use the Services
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        You must be: a) at least the age of majority in the state where you live
        to use this Website and the Services, and b) a resident of the United
        States. If you are under 13 years old, do not use this Website or the
        Services.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Your Use of the Services
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        You may not attempt to gain unauthorized access to any systems or
        networks connected to the Services, by hacking, password “mining” or any
        other illegitimate means. You may not probe, scan or test the
        vulnerability of any network connected to the Services, nor breach the
        security or authentication measures for the Services or any network
        connected to the Services. You agree that you will not take any action
        that imposes an unreasonable or disproportionately large burden on the
        infrastructure of HitchHero’s systems or networks, or any systems or
        networks connected to the Services. You agree not to use any device,
        software or routine to interfere or attempt to interfere with the proper
        working of the Services or with any other person’s use of the Services.
        <br />
        <br />
        You may not manipulate identifiers in order to disguise the origin of
        any message you send through the Services.
        <br />
        <br />
        You may not pretend that you are, or that you represent, someone else,
        or impersonate any other individual or entity.
        <br />
        <br />
        You may not use, sell or otherwise exploit the Services for any
        commercial purposes.
        <br />
        <br />
        You may not use the Services for any purpose that is unlawful or
        prohibited by these Terms of Use.
        <br />
        <br />
        You may not use the Services to post or transmit any content that is
        libelous, defamatory, false, misleading, inaccurate, unlawful, obscene,
        racially or ethnically offensive, or objectionable, or that you have
        reason to believe infringes or otherwise violates the copyright, trade
        secret, patent, publicity, privacy or other intellectual property or
        proprietary rights of any person or entity. The Content you post or
        transmit may not contain profanity, intense violence, nudity or sexually
        explicit images, hate speech, graphic depiction of sexual activity or
        behavior, or the depiction of illegal drug use or the abuse of alcohol,
        drugs, or tobacco. You may not use the Services to port or transmit
        content that violates any law or regulation in the United States,
        Illinois, your home jurisdiction(s), or any jurisdiction where the
        content is stored, transmitted, or delivered.
        <br />
        <br />
        In the case of any violation of these Terms of Use, HitchHero reserves
        the right to 1) restrict or prohibit your use of the Services, and/or 2)
        seek all remedies available by law and in equity for such violations.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Privacy Policy
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        HitchHero’s Privacy Policy applies to personal information you provide
        in connection with the Services. By using the Services you agree to the
        terms of HitchHero’s Privacy Policy.{' '}
        <Link to="/privacy">Click here to see it.</Link>
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          General Disclaimer
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        THE SERVICES ARE PROVIDED “AS IS” WITH ALL FAULTS WITHOUT WARRANTY OF
        ANY KIND, EITHER EXPRESSED OR IMPLIED. HITCHHERO DISCLAIMS ALL
        WARRANTIES, EXPRESSED OR IMPLIED INCLUDING, WITHOUT LIMITATION, THOSE OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OR
        ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
        <br />
        <br />
        HITCHHERO SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL,
        OR INCIDENTAL DAMAGES INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR
        REVENUES, COSTS OF REPLACEMENT GOODS, LOSS OR DAMAGE TO DATA ARISING OUT
        OF THE USE OR INABILITY TO USE THE SERVICES, EVEN IF HITCHHERO HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Indemnification
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        You agree to defend, indemnify, and hold us, our affiliated companies,
        employees, representatives and agents harmless from all liabilities,
        claims, and expenses, including attorneys’ fees, that arise from your
        use or misuse of the Services.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          International Use
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        The Services are intended for use in the United States, and use of the
        Services from countries where the Services are illegal is prohibited.  
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          General Terms
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        HitchHero may modify the Services or discontinue the Services at any
        time without notice. You agree that HitchHero shall not be liable to you
        or to any third party for any modification, suspension or discontinuance
        of the Services.
        <br />
        These Terms of Use and the relationship with you shall be governed by
        the laws of the State of Illinois without regard to its conflict of law
        provisions. You agree that any claims that you may assert against us
        will be resolved exclusively by arbitration in accordance with JAMS
        rules. Any arbitral dispute shall be governed by the substantive laws of
        the State of Illinois and arbitration will occur at a mutual location in
        Chicago, Illinois. You expressly waive the right to a trial by jury in
        any dispute with HitchHero.
        <br />
        <br />
        The failure of HitchHero to exercise or enforce any right or provision
        of these Terms of Service shall not constitute a waiver of such right or
        provision. If for any reason a court finds that any provision or portion
        of these Terms of Use is unenforceable, the remainder of these terms
        will continue in force and effect.
      </Text>
    </Flex>
  </Box>
)

export default Terms
